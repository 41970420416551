import React from "react";
import YouTube from "react-youtube";
import "./styles/youtubeStyles.css";
export default class YoutubeEmbed extends React.Component {
  state = {
    ready: false,
    playing: null,
    player: null,
    opts: {},
  };
  componentDidMount() {
    this.setState({
      const: {
        width: "100%",
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          cc_load_policy: 0, //alt yazıları kapat
          rel: 0, //
          // start: this.props.clickedVideoTime,
          mute: 1,
          autoplay: 1,
          //noNeed: this.props.clickCount, //for same word clicks
        },
      },
    });
  }
  onTimeChanges = (time) => {
    this.state.player.seekTo(time);
  };

  playWord = async (time, duration) => {
    await this.state.player.seekTo(time);
    await this.state.player.playVideo();
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.state.player.pauseVideo();

        resolve();
      }, duration);
    });
  };
  playEachWord = async (time, duration) => {
    await this.state.player.seekTo(time);
    await this.state.player.playVideo();
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, duration);
    });
  };
  playSentence = async (inputWordsArray) => {
    let inputWords = [...inputWordsArray]
    console.log(typeof(inputWords))
    if (typeof(inputWords[0]) === "undefined") {
      console.log("aq")
      this.state.player.pauseVideo();
    } else {
      console.log(inputWords[0]);
      return this.playEachWord(inputWords[0].time / 1000, inputWords[0].duration).then(
        () => {
          inputWords.shift();
          this.playSentence(inputWords);
        }
      );
    }
  };

  /*playClip = (timeArray, durationArray) => {
    this.state.player.seekTo(timeArray[i])

  }*/
  seeksToWord = (time) => {
    console.log("cagiriliyor.");
    this.state.player.seekTo(time);
  };

  loadVideoById = (videoId, timestamp) => {
    this.state.player.loadVideoById(videoId, timestamp);
  };
  _onReady = (event) => {
    this.setState({ player: event.target });
    this.setState({ ready: true });
    event.target.playVideo();
    //event.target.mute();
    this.props.onPlayerReady();
    // access to player in all event handlers via event.target
  };
  _onEnd = (event) => {};
  _onPlay = (event) => {};
  _onPause = (event) => {
    this.setState({ playing: false });
  };
  render() {
    return (
      <YouTube
        videoId={this.props.embedId}
        containerClassName={"youtubeContainer"}
        opts={this.state.opts}
        loading="eager"
        onPlay={this._onPlay}
        onReady={this._onReady}
        onPause={this._onPause}
        noNeed={this.props.clickedVideoTime}
      />
    );
  }
}
