import React from "react";
import "./App.css";
import { Col, Row } from "reactstrap";
import Navi from "./Navi";
import Content from "./Content";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Contact from "./Contact";
import Donate from "./Donate";
import DownloadSubtitles from "./DownloadSubtitles";
import YoutubeWordSearch from "./YoutubePhraseSearch";
import SentenceGenerator from "./SentenceGenerator";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <Navi></Navi>

          <Row style={{ marginTop: "120px", width: "100%" }}>
            <Col md="3"></Col>
            <Col sm="12" md="6">
              <Routes>
                <Route exact path="/" element={<Content />} />
                <Route path="/contact" element={<Contact />} />
                <Route
                  path="/downloadSubtitles"
                  element={<DownloadSubtitles />}
                />
                <Route path="/SentenceGenerator" element={<SentenceGenerator />} />
                <Route path="/donate" element={<Donate />} />
                <Route
                  path="/YoutubePhraseSearch"
                  element={<YoutubeWordSearch />}
                />
              </Routes>
            </Col>
            <Col sm="0" md="3"></Col>
          </Row>
        </Router>
      </header>
    </div>
  );
}

export default App;
