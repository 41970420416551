import React, { Component } from "react";
import {
  Nav,
  Navbar,
  NavbarBrand,
  Collapse,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
} from "reactstrap";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import "./styles/styles.css";
import { Link } from "react-router-dom";

export default class Navi extends Component {
  state={
    isOpen: false,

  }
  render() {
    return (
      <div style={{ position: "relative", height:"100px", width: "100%" }}>
        <Navbar  color="light" expand="md" fixed="top" light >
          <NavbarBrand href="/">
            <h1>
              <span className="sub">Sub</span>
              <span className="tuber">Tuber</span>
            </h1>
          </NavbarBrand>
          <NavbarToggler onClick={()=> {this.setState({isOpen: !this.state.isOpen})}} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="me-auto" navbar>
              <NavItem>
                <NavLink href="/"><DownloadIcon></DownloadIcon>Download Subtitles</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/YouTubePhraseSearch"><SearchIcon></SearchIcon>YouTube Word Search</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/SentenceGenerator"><AutoAwesomeIcon></AutoAwesomeIcon>Sentence Generator</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact"><SupportAgentIcon></SupportAgentIcon>Contact</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
