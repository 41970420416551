import {
  ButtonGroup,
  Button,
  CircularProgress,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { Component } from "react";
import { InputGroup, Input } from "reactstrap";
import { Button as ButtonReact } from "reactstrap";
import YoutubeEmbed from "./YoutubeEmbed";
import { Navigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplayIcon from "@mui/icons-material/Replay";
import Slider from "@mui/material/Slider";
export default class SentenceGenerator extends Component {
  state = {
    url: "",
    videoId: "",
    clickedVideoTime: "0",
    subtitles: [],
    subtitlesWord: ["merhaba", "dunya"],
    clickCount: 0,
    videoDetails: {},
    translationLanguages: {},
    buttonSubtitles: [],
    child: React.createRef(),
    navigateToDownloadPage: false,
    filterWord: "",
    loadingSubtitles: false,
    notFound: true,
    infoMessage: null,
    inputs: [],
    inputWords: [],
  };
  valuetext = (value) => {
    return `${value}.ms`;
  };

  addInputArea = (element) => {
    element.unmodifiedTime = element.time;
    element.unmodifiedDuration = element.duration;
    this.setState({ inputWords: [...this.state.inputWords, element] });
  };
  deleteElements = () => {
    this.state.inputWords.forEach((element) => {
      element.word = "DELETED";
    });
  };
  componentDidMount() {}

  playWord = (word) => {
    this.state.child.current.playWord(word.time / 1000, word.duration);
  };
  playSentence = () => {
    this.state.child.current.playSentence(this.state.inputWords);
  };
  handleDeleteElement(index) {
    this.setState({
      inputWords: this.state.inputWords.filter((el, i) => {
        if (index === i) {
          return false;
        } else {
          return true;
        }
      }),
    });
  }
  handleChangeTimeRange(event, index) {
    this.setState({
      inputWords: this.state.inputWords.map((el, i) => {
        if (index === i) {
          var a = el;
          a.time = event.target.value[0];
          a.duration = event.target.value[1] - event.target.value[0];
          return a;
        }
        return el;
      }),
    });
  }
  handleInputChange = (event) => {
    const url = event.target.value;
    this.setState({
      url: url,
    });
  };

  handleSubmit = () => {
    if (this.state.url.length > 0) {
      this.setState({ infoMessage: "Loading..." });
      this.setState({ loadingSubtitles: true });
      fetch(`https://us-central1-subtuber-3d0e5.cloudfunctions.net/subtitle?videoURL=${this.state.url}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.message !== "no caption") {
            const jsonData = data;
            this.setState({ infoMessage: null });
            this.setState({ loadingSubtitles: false });
            this.setState({
              subtitles: jsonData.words,
            });
            this.setState({
              videoId: jsonData.videoDetails.videoId,
            });
            this.setState({
              videoDetails: jsonData.videoDetails,
            });
            this.setState({
              translationLanguages:
                jsonData.translationLanguages.translationLanguages,
            });
            this.setState({
              notFound: false,
            });
            this.setState({
              buttonSubtitles: this.state.subtitles.map((word, indice) =>
                indice !== 0 ? (
                  <Button
                    color="success"
                    key={word.id}
                    onDoubleClick={() => this.handleTimeClick(word.time)}
                    onClick={() => this.addInputArea(word)}
                  >
                    {word.word}
                  </Button>
                ) : null
              ),
            });
            this.setState(
              {
                subtitlesWord: this.state.subtitles.filter((word) => {
                  if (word.word !== "undefined") {
                    return word.word;
                  }
                }),
              },
              () => {
              }
            );
          } else {
            this.setState({ infoMessage: "No subtitles found!" });
            this.setState({
              subtitles: [{}, { id: 1, word: "No subtitles", time: "0" }],
            });
            this.setState({ loadingSubtitles: false });
          }
        })
        .then(() => {
          this.setState({ inputWords: [] });
        });
    }
  };
  handleTimeClick = (value) => {
    this.setState({
      clickedVideoTime: Math.floor(value / 1000),
      clickCount: this.state.clickCount + 1,
    });
    this.state.child.current.onTimeChanges((value - 300) / 1000);
  };

  updateFilterWords = (event) => {
    this.setState({ filterWord: event.target.value });
  };
  onPlayerReady = () => {};
  render() {
    return (
      <div>
        <h1>Sentence Generator</h1>
        <InputGroup
          style={{
            display: "inline-flex",
            width: "75%",
            paddingTop: "105px",
            paddingBottom: "55px",
          }}
        >
          <Input
            type="url"
            value={this.state.url}
            onChange={this.handleInputChange}
            placeholder="Paste YouTube Link to Get Subtitles"
          ></Input>
          <LoadingButton
            onClick={this.handleSubmit}
            loading={this.state.loadingSubtitles}
            loadingIndicator={
              <CircularProgress
                role="progressbar"
                style={{ background: "darkred", color: "white" }}
                size={26}
              >
                Loading
              </CircularProgress>
            }
            variant="contained"
          >
            Search!
          </LoadingButton>
        </InputGroup>

        {<p>{this.state.infoMessage}</p>}
        {this.state.videoId && (
          <div style={{ paddingBottom: "25px" }}>
            <YoutubeEmbed
              onPlayerReady={this.onPlayerReady}
              ref={this.state.child}
              embedId={this.state.videoId}
              clickCount={this.state.clickCount}
              subtitles={this.state.subtitles}
            ></YoutubeEmbed>
          </div>
        )}
        {this.state.inputWords.length > 0 &&
          this.state.inputWords.map((element, i) => {
            return (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <ButtonReact disabled style={{ minWidth: "150px" }}>
                    {element.word}
                  </ButtonReact>

                  <ButtonReact
                    onClick={() => {
                      this.playWord(element);
                    }}
                  >
                    <ReplayIcon />
                  </ButtonReact>
                </div>
                <Box sx={{ width: 300 }}>
                  <Slider
                    getAriaLabel={() => "Time range"}
                    key={i}
                    value={[element.time, element.time + element.duration]}
                    min={element.unmodifiedTime - 400}
                    max={
                      element.unmodifiedTime + element.unmodifiedDuration + 400
                    }
                    onChange={(e) => {
                      this.handleChangeTimeRange(e, i);
                    }}
                    valueLabelDisplay="auto"
                  />
                </Box>

                <ButtonReact
                  key={i}
                  onClick={() => {
                    this.handleDeleteElement(i);
                  }}
                >
                  <DeleteIcon color="error"></DeleteIcon>
                </ButtonReact>
              </div>
            );
          })}
        {this.state.inputWords.length > 0 && (
          <div>
            <ButtonReact
              onClick={() => {
                this.playSentence();
              }}
            >
              Play
            </ButtonReact>
          </div>
        )}

        {!this.state.notFound && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Input
              type="search"
              value={this.state.filterWord}
              onChange={this.updateFilterWords}
              placeholder="Search a word"
              style={{ width: "300px" }}
            ></Input>

            <ButtonGroup
              style={{
                display: "flow-root",
                overflow: "overlay",
                height: "400px",
              }}
              variant="text"
              aria-label="text button group"
            >
              {this.state.navigateToDownloadPage && (
                <Navigate
                  state={[
                    this.state.videoDetails,
                    this.state.translationLanguages,
                  ]}
                  to="/download-subtitles"
                ></Navigate>
              )}

              {this.state.filterWord.length > 1
                ? this.state.buttonSubtitles.filter((element) => {
                    if (element === null) {
                      return false;
                    } else {
                      return (
                        element.props.children
                          .toLowerCase()
                          .indexOf(this.state.filterWord.toLowerCase()) > -1
                      );
                    }
                  })
                : this.state.buttonSubtitles}
            </ButtonGroup>
          </div>
        )}
      </div>
    );
  }
}
