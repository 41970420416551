import { Box } from "@mui/material";
import React, { useEffect } from "react";

export default function Contact() {
  return (
    <div>
      <Box sx={{ p: 2, border: "1px dashed grey" }}>
        <h1>Contact me!</h1>
        <h2>
          Let me know if you have any questions or suggestions that you would
          like to see as a feature on SubTuber.
        </h2>
        <h3>E-mail: subtubercontact@gmail.com</h3>
      </Box>
    </div>
  );
}
