import { ButtonGroup, Button, CircularProgress, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, { Component } from "react";
import { InputGroup, Input } from "reactstrap";
import YoutubeEmbed from "./YoutubeEmbed";
import { Navigate } from "react-router-dom";

export default class Content extends Component {
  state = {
    url: "",
    videoId: "",
    clickedVideoTime: "0",
    subtitles: [],
    clickCount: 0,
    videoDetails: {},
    translationLanguages: {},
    buttonSubtitles: [],
    child: React.createRef(),
    navigateToDownloadPage: false,
    filterWord: "",
    loadingSubtitles: false,
    notFound: true,
    infoMessage: null,
  };

  componentDidMount() {}

  handleInputChange = (event) => {
    const url = event.target.value;
    this.setState({
      url: url,
    });
  };

  handleSubmit = () => {
    if (this.state.url.length > 0) {
      this.setState({ infoMessage: "Loading..." });
      this.setState({ loadingSubtitles: true });
      fetch(
        `https://us-central1-subtuber-3d0e5.cloudfunctions.net/subtitle?videoURL=${this.state.url}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.message !== "no caption") {
            const jsonData = data;
            this.setState({ infoMessage: null });
            this.setState({ loadingSubtitles: false });
            this.setState({
              subtitles: jsonData.words,
            });
            this.setState({
              videoId: jsonData.words[0].videoId,
            });
            this.setState({
              videoDetails: jsonData.videoDetails,
            });

            if (jsonData.translationLanguages)
              this.setState({
                translationLanguages:
                  jsonData.translationLanguages.translationLanguages,
              });
            this.setState({
              notFound: false,
            });
            this.setState({
              buttonSubtitles: this.state.subtitles.map((word, indice) =>
                indice !== 0 ? (
                  <Button
                    color="success"
                    key={word.id}
                    onClick={() => this.handleTimeClick(word.time)}
                  >
                    {word.word}
                  </Button>
                ) : null
              ),
            });
          } else {
            this.setState({ infoMessage: "No subtitles found!" });
            this.setState({
              subtitles: [{}, { id: 1, word: "No subtitles", time: "0" }],
            });
            this.setState({ loadingSubtitles: false });
          }
        });
    }
  };
  handleTimeClick = (value) => {
    this.setState({
      clickedVideoTime: Math.floor(value / 1000),
      clickCount: this.state.clickCount + 1,
    });
    this.state.child.current.onTimeChanges((value - 300) / 1000);
  };
  downloadClickHandler = () => {
    this.setState({ navigateToDownloadPage: true });
  };
  updateFilterWords = (event) => {
    this.setState({ filterWord: event.target.value });
  };
  onPlayerReady = () => {};
  render() {
    return (
      <div>
        <h1>
          Youtube Subtitles Downloader / Youtube Video Downloader / Youtube
          Advanced Word Search
        </h1>

        <InputGroup
          style={{
            display: "inline-flex",
            width: "75%",
            paddingTop: "105px",
            paddingBottom: "55px",
          }}
        >
          <Input
            type="url"
            value={this.state.url}
            onChange={this.handleInputChange}
            placeholder="Paste YouTube Link to Get Subtitles"
          ></Input>
          <LoadingButton
            onClick={this.handleSubmit}
            loading={this.state.loadingSubtitles}
            loadingIndicator={
              <CircularProgress
                role="progressbar"
                style={{ background: "darkred", color: "white" }}
                size={26}
              >
                Loading
              </CircularProgress>
            }
            variant="contained"
          >
            Search!
          </LoadingButton>
        </InputGroup>

        {<p>{this.state.infoMessage}</p>}
        {this.state.videoId && (
          <div style={{ paddingBottom: "25px" }}>
            <iframe
              id="buttonApi"
              src={
                "https://yt-download.org/api/button/mp4?url=https://www.youtube.com/watch?v=" +
                this.state.videoId
              }
              width="100%"
              height="100%"
              allowtransparency="true"
              scrolling="no"
              style={{ mixBlendMode: "difference" }}
            ></iframe>
            <YoutubeEmbed
              onPlayerReady={this.onPlayerReady}
              ref={this.state.child}
              embedId={this.state.videoId}
              clickCount={this.state.clickCount}
              subtitles={this.state.subtitles}
            ></YoutubeEmbed>
          </div>
        )}

        {!this.state.notFound && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Input
              type="search"
              value={this.state.filterWord}
              onChange={this.updateFilterWords}
              placeholder="Search a word"
              style={{ width: "300px" }}
            ></Input>
            <Button onClick={this.downloadClickHandler}>
              DOWNLOAD SUBTITLES
            </Button>

            <ButtonGroup
              style={{
                display: "flow-root",
                overflow: "overlay",
                height: "400px",
              }}
              variant="text"
              aria-label="text button group"
            >
              {this.state.navigateToDownloadPage && (
                <Navigate
                  state={[
                    this.state.videoDetails,
                    this.state.translationLanguages,
                  ]}
                  to="/downloadSubtitles"
                ></Navigate>
              )}

              {this.state.buttonSubtitles.filter((element) => {
                if (this.state.filterWord.length < 2) {
                  return true;
                } else if (element === null) {
                  return false;
                }

                return element.props.children
                  .toLowerCase()
                  .includes(this.state.filterWord.toLowerCase());
              })}
            </ButtonGroup>
          </div>
        )}
        <h2>What is SubTuber</h2>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          <h4>
            SubTuber is a free YouTube subtitles downloader tool. SubTuber
            allows you to download subtitles, download youtube video
            transcripts, download texts from youtube, download youtube video as
            mp3, download youtube video as mp4, download youtube video as webm.
            SubTuber has an advanced "Youtube Word Search" tool. This tool
            allows you to search any specific word in our YouTube subtitles
            database.
          </h4>
          <a href="/YouTubeWordSearch">Go to YouTubeWordSearch</a>
          <h4>
            {" "}
            select any supported language from the list and type any word you
            want Let SubTuber bring the video to you and jump to the moment of
            the word.
          </h4>
          <h4>
            and SubTuber has a simple "YouTube Word Search" tool. This tool
            allows you to see all words of specific youtube video one by one,
            jump to the selected word in the videos with one click. SubTuber is
            an alternative to DownSub and SaveSubs. We have a functional youtube
            player that you can search any words in youtube video and seeks to
            the timestamp including word. Subtitles Downloader will support many
            sites in future. Please let me know about the features you want to
            see on SubTuber
          </h4>
        </Box>

        <h3>How to use SubTuber</h3>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          <h4>1.Copy youtube video link</h4>
          <img
            src="https://i.ibb.co/jgqH4Qw/htu1.png"
            alt="download captions"
            loading="lazy"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          <h4>2.Paste it into input area on subtuber.com</h4>
          <img
            src="https://i.ibb.co/pxdYCYx/htu2.png"
            alt="download captions subtitles"
            loading="lazy"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          <h4>
            3.You can filter any word and seeks to timestamp of selected word in
            video
          </h4>
          <img
            src="https://i.ibb.co/dPg5XSg/htu3.png"
            alt="download captions subtitles translated"
            loading="lazy"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          <h4>4. Click "Download Txt" to download subtitles</h4>
          <img
            src="https://i.ibb.co/B4qjnN6/htu4.png"
            alt="how to use 4"
            loading="downloader captions subtitles translated as txt"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
        <Box sx={{ p: 2, border: "1px dashed grey" }}>
          <h4>5. That's all. You are free to download</h4>
          <img
            src="https://i.ibb.co/yPH6spX/htu5.png"
            alt="download subtitles as text from youtube"
            loading="lazy"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      </div>
    );
  }
}
