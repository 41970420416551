import LoadingButton from "@mui/lab/LoadingButton";
import { MenuItem, Select, CircularProgress, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import YoutubeEmbed from "./YoutubeEmbed";

export default function DownloadSubtitles() {
  let location = useLocation();
  const [subtitles, setSubtitles] = useState([]);
  const [justWords, setJustWords] = useState([]);
  const [selectedLang, setSelectedLang] = useState("");
  const [wordsWithTime, setWordsWithTime] = useState([]);
  const [title, setTitle] = useState("");
  const [justWordsTranslated, setJustWordsTranslated] = useState([]);
  const [wordsWithTimeTranslated, setWordsWithTimeTranslated] = useState([]);
  const [loadingSubtitles, setLoadingSubtitles] = useState(true);
  const [loadingSubtitlesTranslated, setLoadingSubtitlesTranslated] = useState(
    true
  );
  const [defaultLanguage, setDefaultLanguage] = useState("");
  useEffect(() => {
    setDefaultLanguage(location.state[0].languageCode);
  }, []);
  useEffect(async () => {
    setTitle(location.state[0].title);
    if (selectedLang === "") {
      await fetch(
        `https://us-central1-subtuber-3d0e5.cloudfunctions.net/downloadSubtitles?videoURL=https://youtube.com/watch?v=${location.state[0].videoId}`
      )
        .then((res) => res.json())
        .then((data) => {
          setSubtitles(data);
          prepareText(data);
          setLoadingSubtitles(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await fetch(
        `https://us-central1-subtuber-3d0e5.cloudfunctions.net/downloadSubtitles?videoURL=https://youtube.com/watch?v=${location.state[0].videoId}&lang=${selectedLang}`
      )
        .then((res) => res.json())
        .then((data) => {
          setSubtitles(data);
          prepareTextTranslated(data);
          setLoadingSubtitlesTranslated(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedLang]);
  const prepareText = (data) => {
    const justWordsVar = data.map((obj, index) => {
      if (obj.word.charAt(0) === obj.word.charAt(0).toUpperCase()) {
        return "\n" + obj.word;
      } else {
        return obj.word;
      }
    });
    const wordsWithTimeVar = data.map((obj, index) => {
      if (obj.word.charAt(0) === obj.word.charAt(0).toUpperCase()) {
        return "\n" + obj.word + `${obj.timestamp} `;
      } else {
        return obj.word + `${obj.timestamp} `;
      }
    });
    setJustWords(justWordsVar);
    setWordsWithTime(wordsWithTimeVar);
  };
  const prepareTextTranslated = (data) => {
    const justWordsVar = data.map((obj, index) => {
      if (obj.word.charAt(0) === obj.word.charAt(0).toUpperCase()) {
        return "\n" + obj.word;
      } else {
        return obj.word;
      }
    });
    const wordsWithTimeVar = data.map((obj, index) => {
      if (obj.word.charAt(0) === obj.word.charAt(0).toUpperCase()) {
        return "\n" + obj.word + `${obj.timestamp} `;
      } else {
        return obj.word + `${obj.timestamp} `;
      }
    });
    setJustWordsTranslated(justWordsVar);
    setWordsWithTimeTranslated(wordsWithTimeVar);
  };
  const downloadTxtFileWithTimestamps = (language, title) => {
    const element = document.createElement("a");

    const file = new Blob(wordsWithTime, {
      type: "text/plain",
    });
    let reg = /[?*\\/:"<>|]/g;
    let newTitle = title.replace(reg, "-");
    element.href = URL.createObjectURL(file);
    element.download = `[${language}](timestamp)${newTitle}[SubTuber.com].txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const downloadTxtFile = (language, title) => {
    const element = document.createElement("a");

    const file = new Blob(justWords, {
      type: "text/plain",
    });
    let reg = /[?*\\/:"<>|]/g;
    let newTitle = title.replace(reg, "-");
    element.href = URL.createObjectURL(file);
    element.download = `[${language}]${newTitle}[SubTuber.com].txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const downloadTxtFileTranslatedWithTimestamps = (language, title) => {
    const element = document.createElement("a");

    const file = new Blob(wordsWithTimeTranslated, {
      type: "text/plain",
    });
    let reg = /[?*\\/:"<>|]/g;
    let newTitle = title.replace(reg, "-");
    element.href = URL.createObjectURL(file);
    element.download = `[${language}](timestamp)${newTitle}[SubTuber.com].txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const downloadTxtFileTranslated = (language, title) => {
    const element = document.createElement("a");

    const file = new Blob(justWordsTranslated, {
      type: "text/plain",
    });
    let reg = /[?*\\/:"<>|]/g;
    let newTitle = title.replace(reg, "-");
    element.href = URL.createObjectURL(file);
    element.download = `[${language}]${newTitle}[SubTuber.com].txt`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  const handleLanguageChange = (event) => {
    console.log(event);
    setSelectedLang(event.target.value);
    setLoadingSubtitlesTranslated(true);
  };
  const onPlayerReady = () => {};

  return (
    <div>
      <Box sx={{ p: 2, border: "1px dashed grey" }}>

      {location.state[0].videoId ? (
        <div>
          <YoutubeEmbed
            onPlayerReady={onPlayerReady}
            embedId={location.state[0].videoId}
          ></YoutubeEmbed>
        </div>
      ) : null}

      <LoadingButton
        onClick={() => downloadTxtFile(defaultLanguage, title)}
        loading={loadingSubtitles}
        loadingIndicator={
          <CircularProgress
            role="progressbar"
            style={{ background: "darkred", color: "white" }}
            size={26}
          >
            Loading
          </CircularProgress>
        }
        variant="contained"
      >
        Download Subtitles txt
      </LoadingButton>
      <LoadingButton
        onClick={() => downloadTxtFileWithTimestamps(defaultLanguage, title)}
        loading={loadingSubtitles}
        loadingIndicator={
          <CircularProgress
            role="progressbar"
            style={{ background: "darkred", color: "white" }}
            size={26}
          >
            Loading
          </CircularProgress>
        }
        variant="contained"
      >
        Download Subtitles with Timestamps
      </LoadingButton>
      </Box>
      <Box sx={{ p: 2, border: "1px dashed grey" }}>
      <p>If you want another language you can select from the list.</p>
      <Select
        style={{ backgroundColor: "white", width: "250px", margin: "50px" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedLang}
        label="Language"
        onChange={handleLanguageChange}
      >
        {location.state[1].map((value) => {
          return (
            <MenuItem value={value.languageCode}>
              {value.languageName.simpleText}
            </MenuItem>
          );
        })}
      </Select>
      {selectedLang.length > 0 && (
        <div style={{ paddingBottom: "50px" }}>
          <LoadingButton
            loadingIndicatorStart={{ background: "darkred", color: "white" }}
            onClick={() => downloadTxtFileTranslated(selectedLang, title)}
            loading={loadingSubtitlesTranslated}
            loadingIndicator={
              <CircularProgress
                role="progressbar"
                style={{ background: "darkred", color: "white" }}
                size={26}
              >
                Loading
              </CircularProgress>
            }
            variant="contained"
          >
            Download in {selectedLang}
          </LoadingButton>
          <LoadingButton
            loadingIndicatorStart={{ background: "darkred", color: "white" }}
            onClick={() =>
              downloadTxtFileTranslatedWithTimestamps(selectedLang, title)
            }
            loading={loadingSubtitlesTranslated}
            loadingIndicator={
              <CircularProgress
                role="progressbar"
                style={{ background: "darkred", color: "white" }}
                size={26}
              >
                Loading
              </CircularProgress>
            }
            variant="contained"
          >
            Download in {selectedLang} with timestamps
          </LoadingButton>
        </div>
      )}
      </Box>
      <Box sx={{ p: 2, border: "1px dashed grey" }}>
        <h1>YouTube Downloader</h1>
        <h2>
          You can download from youtube with any format.
        </h2>
      </Box>
      <iframe
        id="widgetApi"
        src={
          "https://yt-download.org/api/widget?url=https://www.youtube.com/watch?v=" +
          location.state[0].videoId
        }
        width="100%"
        height="560px"
        allowtransparency="true"
        style={{ border: "none" }}
      ></iframe>
    </div>
  );
}
//
