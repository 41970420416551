import { Box, MenuItem, Select } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Button, Input, InputGroup } from "reactstrap";
import { useTitle } from "./UseTitle";
import YoutubeEmbed from "./YoutubeEmbed";

export default function YoutubePhraseSearch() {
  const [word, setWord] = useState("");
  const [phrase, setPhrase] = useState("");
  const [selectedLang, setSelectedLang] = useState("");
  const [videoIds, setVideoIds] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [currentVideoId, setCurrentVideoId] = useState();
  const [currentTimestamp, setCurrentTimestamp] = useState();
  const [infoMessage, setInfoMessage] = useState();
  const languages = {
    de: "German",
    en: "English",
    es: "Spanish",
    fr: "French",
    id: "Indonesian",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    nl: "Dutch",
    pt: "Portuguese",
    ru: "Russian",
    tr: "Turkish",
    vi: "Vietnamese",
  };
  useTitle("YouTube Phrase Search / YouTube Word Search");
  const youtubeRef = useRef(null);
  const levenshteinDistance = (str1 = "", str2 = "") => {
    const track = Array(str2.length + 1)
      .fill(null)
      .map(() => Array(str1.length + 1).fill(null));
    for (let i = 0; i <= str1.length; i += 1) {
      track[0][i] = i;
    }
    for (let j = 0; j <= str2.length; j += 1) {
      track[j][0] = j;
    }
    for (let j = 1; j <= str2.length; j += 1) {
      for (let i = 1; i <= str1.length; i += 1) {
        const indicator = str1[i - 1] === str2[j - 1] ? 0 : 1;
        track[j][i] = Math.min(
          track[j][i - 1] + 1, // deletion
          track[j - 1][i] + 1, // insertion
          track[j - 1][i - 1] + indicator // substitution
        );
      }
    }
    return track[str2.length][str1.length];
  };
  const submitHandler = () => {
    console.log("submit basildi");
    setTimestamps([]);
    setCurrentTimestamp(null);
    setVideoIds([]);
    setCurrentVideoId(null);
    setInfoMessage(null);
    fetch(`https://us-central1-subtuber-3d0e5.cloudfunctions.net/searchWord?lang=${selectedLang}&word=${word}`)
      .then((resp) => resp.json())
      .then((data) => {
        console.log(data);
        if (data.message !== "Word not found!") {
          let videoIdArr = data.videoIds.map((item) => item.key);
          let timestampArr = data.videoIds.map((item) => item.value.timestamps);
          let sentenceFound = false;
          setVideoIds(videoIdArr);
          for (let i = 0; i < timestampArr.length; i++) {
            for (let j = 0; j < timestampArr[i].length; j++) {
              let sentence = timestampArr[i][j].sentence;

              let phrases = [];
              let wordsOfPhrases = sentence.split(" ");
              let cumulativePhrase = "";
              for (let k = 0; k < wordsOfPhrases.length; k++) {
                if (k != 0) {
                  cumulativePhrase += " " + wordsOfPhrases[k];
                } else {
                  cumulativePhrase += wordsOfPhrases[k];
                }
                phrases[k] = cumulativePhrase;
                //console.log(cumulativePhrase);
              }
              sentenceFound=false;
              phrases.forEach((phraseElement) => {
              /*  console.log(
                  phrase +
                    " " +
                    phraseElement +
                    " in edit distance'ı: " +
                    levenshteinDistance(phrase, phraseElement)
                );*/
                let phraseLength = phrase.split(" ").length;
                if (levenshteinDistance(phrase, phraseElement) < phraseLength) {
                  
                  console.log(timestampArr[i][j].timestamp);
                  setInfoMessage(null);
                  setVideoIds((videoIds) => [...videoIds, videoIdArr[i]]);
                  setTimestamps((timestamps) => [
                    ...timestamps,
                    timestampArr[i][j].timestamp,
                  ]);
                  if(!sentenceFound){
                    setCurrentVideoId(videoIdArr[i]);
                  setCurrentTimestamp(timestampArr[i][j].timestamp);
                  }
      
                  sentenceFound = true;
                }
              });
            }
          }
          /*
          timestampArr[0].forEach((sentence,index)=>{
            if(sentence.sentence.includes(phrase)){
              setCurrentVideoId(videoIdArr[0]);
              setCurrentTimestamp(timestampArr[0][index].timestamp);
              setInfoMessage(null);
              sentenceFound = true;
            }
          })*/
          if (!sentenceFound) {
            setInfoMessage("Not Found!");
          } else {
            youtubeRef.current.loadVideoById(
              currentVideoId,
              (timestampArr[0][0].timestamp - 500) / 1000
            );
          }

          console.log(timestampArr);
        } else {
          setInfoMessage('"' + word + '"' + " not found!");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeHandler = (event) => {
    console.log("selam");
    console.log(event.target.value);
    if (event.target.value.toString().includes(" ")) {
      setWord(
        event.target.value
          .toString()
          .trim()
          .substring(0, event.target.value.toString().indexOf(" "))
      );
    } else {
      setWord(event.target.value.toString().trim());
    }
    setPhrase(event.target.value.toString());
  };
  const languageChangeHandler = (event) => {
    setSelectedLang(event.target.value);
  };
  const playerReadyHandler = () => {
    youtubeRef.current.seeksToWord((currentTimestamp - 500) / 1000.0);
  };
  return (
    <div>
      <Box sx={{ p: 2, border: "1px dashed grey" }}>
        <h1>YouTube Word Search / YouTube Phrase Search</h1>
        <h2>
          Search any word / phrase in selected language. You can select the language you
          want from the list. Search a word / search phrase in our subtitles database and let the video
          including the word / phrase come.
          YouTube Phrase Finder helps you to find any phrase in YouTube videos.
          YouTube Phrase Search , YouTube Word Search , YouTube Word Finder, YouTube Sentence Search, YouTube Captions Search, Search Phrase From YouTube Videos
        </h2>
      </Box>

      <h3>
        Supported Languages: "German", "English", "Spanish", "French",
        "Indonesian", "Italian", "Japanese", "Korean", "Dutch", "Portuguese",
        "Russian", "Turkish", "Vietnamese"
      </h3>

      <Select
        style={{ backgroundColor: "white", width: "250px", margin: "50px" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedLang}
        label="Language"
        onChange={languageChangeHandler}
      >
        {Object.entries(languages).map(([key, value]) => {
          return <MenuItem value={key}>{value}</MenuItem>;
        })}
      </Select>
      {selectedLang && (
        <InputGroup>
          <Input
                     value={phrase}
            onChange={changeHandler}
            placeholder="Type a word to search"
          ></Input>
          <Button onClick={submitHandler}> Search!</Button>
        </InputGroup>
      )}

      {infoMessage && <p>{infoMessage}</p>}
      {currentVideoId && (
        <YoutubeEmbed
          ref={youtubeRef}
          onPlayerReady={playerReadyHandler}
          embedId={currentVideoId}
        ></YoutubeEmbed>
      )}

      <h2>
        YouTube Word Search allows you to find any word in YouTube videos. You
        can search captions in YouTube videos. This is also called Video Word
        Finder. Video Word Finder is a search tool in YouTube videos.
      </h2>
    </div>
  );
}
